import React, { Component } from "react"
import Formsy from 'formsy-react';
import { connect } from "react-redux"
import axios from 'axios'




import Layout from "../components/layout"
// import Wizard from "../components/wizard"
import vehicalIcon from "../images/vehical-icon.svg"
import bottomLine from "../images/line.png"
// import MyInput from "../components/MyInput";
import MySelect from "../components/MySelect";
// import MyCheckbox from "../components/MyCheckbox";

import SEO from "../components/seo"
// import { APPLLOCLIENT } from "../components/constant";
import ButtonLoader from "../components/buttonLoader";
import * as actionCreators from './../store/actions';
import MyInputMask from "../components/MyInputMask";
// import { formatQueryParams } from "../core/util";
// import { YEAR_QUERY, GET_USERBYID_QUERY, GET_MAKERLIST_QUERY, GET_MODELLIST_QUERY } from "../components/Queryconstant";


class ApiIntegrationPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canSubmit: false,
      serviceSubmit: false,
      isValueChangeError: false,
      selectedYear: null,
      selectedMake: null,
      formData: null,
      selectedService: null,
      shopList: [],
      yearList: [
        { title: 'Select a Year...', value: '' },
      ],
      makeList: [
        { title: 'Select a Make...', value: '' },
      ],
      modelList: [
        { title: 'Select a Modal...', value: '' },
      ],
      serviceList: [
        { title: 'Select a Service...', value: '' },
      ]

    };
  }

  componentDidMount = async () => {
    axios
      .get(`https://repairpal.com/api/v1/emu/makes`)
      .then(makes => {
        console.log('makes22222', makes);
        if (makes.data.length > 0) {
          let newMake = [];
          makes.data.forEach(element => {
            newMake.push({
              title: element.make_name,
              value: element.id
            })
          });
          this.setState({ makeList: newMake })
        }
      })
      .catch(error => {
        console.log('error', error);
      })
  }


  selectionChange = (value, type) => {
    if (type === 'make') {
      this.setState({ selectedMake: value });
      this.getYearFromMake(value);
    }
    if (type === 'year') {
      this.setState({ selectedYear: value });
      this.getModelFromMake(value);
    }
  }

  getYearFromMake = (make) => {
    axios
      .get(`https://repairpal.com/api/v1/emu/years?make=${make}`)
      .then(years => {
        console.log('years22222', years);
        if (years.data.length > 0) {
          let newYears = [];
          years.data.forEach(element => {
            newYears.push({
              title: element.year,
              value: element.year
            })
          });
          this.setState({ yearList: newYears })
        }
      })
      .catch(error => {
        console.log('error', error);
      })
  }

  getModelFromMake = (year) => {
    const { selectedMake } = this.state;
    axios
      .get(`https://repairpal.com/api/v1/emu/basevehicles?make=${selectedMake}&year=${year}`)
      .then(models => {
        console.log('model22222', models);
        if (models.data.length > 0) {
          let newModels = [];
          models.data.forEach(element => {
            newModels.push({
              title: element.model_name,
              value: element.id
            })
          });
          this.setState({ modelList: newModels })
        }
      })
      .catch(error => {
        console.log('error', error);
      })
  }

  disableButton = () => {
    this.setState({ canSubmit: false });
  }

  disableServiceButton = () => {
    this.setState({ serviceSubmit: false });
  }

  enableServiceButton = () => {
    this.setState({ serviceSubmit: true });
  }

  enableButton = () => {
    this.setState({ canSubmit: true });
  }

  submit = (model) => {
    this.setState({ formData: model })
    axios
      .get(`https://repairpal.com/api/v1/emu/operations/popular_votes?base_vehicle=${model.model}`)
      .then(services => {
        if (services.data.length > 0) {
          let newServices = [{ title: 'Select a Service...', value: '' }];
          services.data.forEach(element => {
            newServices.push({
              title: element.name,
              value: element.id,
            })
          });
          this.setState({ serviceList: newServices })
        }
      })
      .catch(error => {
        console.log('error', error);
      })
  }

  selectServiceSubmit = (model) => {
    const { formData } = this.state;
    console.log('model', model)
    console.log('formData', formData)
    axios
      .get(`https://repairpal.com/api/v1/shops?postcode=${formData.zipCode}&api_key=6474b23e-8274-495b-8527-7b81139ca080`)
      .then(shops => {
        this.setState({ shopList: shops.data.data })
        console.log(shops, 'shops')
      })
      .catch(error => {
        console.log('error', error);
      })
    // axios
    // .get(`https://repairpal.com/api/v1/emu/repairs?utf8=%E2%9C%93&version=nrp2&base_vehicle=${formData.model}&operation=${model}&postcode=${formData.zipCode}`)
    // .then(repairs => {
    //   console.log(repairs,'repairs')
    // })
    // .catch(error => {
    //   console.log('error', error);
    // })

  }

  onServiceChange = (value) => {
    this.setState({ selectedService: value })
  }

  bookAppointment = () => {
    let payload = { "service_location_appointment": { "first_name": "testcustomer", "last_name": "test", "email": "demo1.webfirminfotech@gmail.com", "phone": "(897) 978-7884", "service": "testing", "date": "2019-07-10", "minutes": 510, "service_location_id": 177171, "emu_base_vehicle_id": 76691 } }
    axios.post('https://repairpal.com/service_location_appointments', payload, {
      headers: { 
        'Content-Type': 'application/json', 
        'X-CSRF-Token': 'y364qo9vCyB3nN7lD2KWhtIfGuZxnXVMy67aTsdY4n9iVeK13lFvDJzaCHVPAe4oOecsTEqqWXWf7+9KIEYf+w==',
      }
    })
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  render() {
    const { step1Data } = this.props;
    const { yearList, makeList, modelList, canSubmit, serviceSubmit, serviceList, shopList } = this.state;
    const zip = this.props.location && this.props.location.state && this.props.location.state.zipCode ? this.props.location.state.zipCode : '';
    console.log('shopList', shopList)
    console.log('makeList', makeList)
    return (
      <Layout layoutType="wizard" >
        <SEO title="Vehicle Info" keywords={[`Servicecontract.com`, `service contracts`, `Extended warranty`]} />
        <div className="chat-content">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="26.496" viewBox="0 0 30 26.496">
            <g id="Group_157" data-name="Group 157" transform="translate(-309.85 -157.354)">
              <g id="Group_27" data-name="Group 27" transform="translate(309.85 157.354)">
                <path id="Path_73" data-name="Path 73" d="M309.85,170.605c0,7.3,6.733,13.245,15,13.245a16.468,16.468,0,0,0,7.738-1.9,8.731,8.731,0,0,0,4.123.967,10.089,10.089,0,0,0,1.427-.1,1.14,1.14,0,0,0,.46-2.089,4.986,4.986,0,0,1-1.8-2.132,12.3,12.3,0,0,0,3.045-8c0-7.3-6.733-13.245-15-13.245S309.85,163.3,309.85,170.605Zm1.5,0c0-6.476,6.058-11.744,13.5-11.744s13.5,5.269,13.5,11.744a10.721,10.721,0,0,1-2.971,7.345.756.756,0,0,0-.153.772,6.667,6.667,0,0,0,1.795,2.7,7.254,7.254,0,0,1-4.044-.968.717.717,0,0,0-.386-.1.745.745,0,0,0-.373.1,14.929,14.929,0,0,1-7.37,1.911C317.409,182.349,311.351,177.081,311.351,170.605Z" transform="translate(-309.85 -157.354)" fill="#fff" />
                <circle id="Ellipse_1" data-name="Ellipse 1" cx="1.924" cy="1.924" r="1.924" transform="translate(13.085 11.327)" fill="#fff" />
                <circle id="Ellipse_2" data-name="Ellipse 2" cx="1.924" cy="1.924" r="1.924" transform="translate(17.943 11.327)" fill="#fff" />
                <circle id="Ellipse_3" data-name="Ellipse 3" cx="1.924" cy="1.924" r="1.924" transform="translate(8.227 11.327)" fill="#fff" />
              </g>
            </g>
          </svg>

        </div>
        <section>

          <div className="custom-container">
            <div className="title-container">
              <div className="icon-image">
                <img src={vehicalIcon} alt="icon" />
              </div>
              <div className="title-text">
                <h1>API Integration Page</h1>
                <p>Providing your vehicle’s year, make, model, and mileage helps us narrow down your options.</p>
              </div>
            </div>

            <div className="body-content">
              <Formsy ref="vehicalInfoForm" onValidSubmit={this.submit} onValid={this.enableButton} onInvalid={this.disableButton}>
                <div className="form-container-inline">
                  <div className="form-left">
                    <div className="form-label-group">
                      <MySelect
                        name="maker"
                        required={true}
                        value={step1Data && step1Data.vehicleInfo && step1Data.vehicleInfo.maker ? step1Data.vehicleInfo.maker : ''}
                        selecteChange={(e) => this.selectionChange(e, 'make')}
                        options={makeList}
                      />
                    </div>
                    <div className="form-label-group">
                      <MySelect
                        name="year"
                        value={step1Data && step1Data.vehicleInfo && step1Data.vehicleInfo.year ? step1Data.vehicleInfo.year : ''}
                        required={true}
                        selecteChange={(e) => this.selectionChange(e, 'year')}
                        options={yearList}
                      />
                    </div>

                    <div className="form-label-group">
                      <MySelect
                        name="model"
                        value={step1Data && step1Data.vehicleInfo && step1Data.vehicleInfo.model ? step1Data.vehicleInfo.model : ''}
                        required={true}
                        options={modelList}
                      />
                    </div>
                    <div className="form-label-group">
                      <MyInputMask
                        name="zipCode"
                        id="zipCode"
                        placeholder="Zip Code"
                        mask="99999"
                        validations={{
                          maxLength: 5,
                          minLength: 5
                        }}
                        validationError="Please enter a valid 5 numbers."
                        value={step1Data && step1Data.vehicleInfo && step1Data.vehicleInfo.zipCode ? step1Data.vehicleInfo.zipCode : zip ? zip : ''}
                        isLabelRequired={true}
                        className="form-control"
                        required
                      />
                    </div>
                  </div>
                </div>
                <button type="submit" disabled={!canSubmit} className={!canSubmit ? 'btn disabled wizard-btn vehicle-btn' : 'btn wizard-btn vehicle-btn'}>
                  <ButtonLoader isLoading={false} label={'continue'} />
                </button>
              </Formsy>
              <button onClick={() => this.bookAppointment()} className="btn">
                    BookAppointment
                  </button>
            </div>
            {/* New Form */}
            <div className="body-content" style={{ paddingTop: 30 }}>
              <Formsy ref="selectServiceForm" onValidSubmit={this.selectServiceSubmit} onValid={this.enableServiceButton} onInvalid={this.disableServiceButton}>
                <div className="form-container-inline">
                  <div className="form-left">
                    <div className="form-label-group">
                      <MySelect
                        name="selectService"
                        required={true}
                        value={''}
                        selecteChange={(e) => this.onServiceChange(e)}
                        options={serviceList}
                      />
                    </div>
                  </div>
                </div>
                <button type="submit" disabled={!serviceSubmit} className={!serviceSubmit ? 'btn disabled wizard-btn vehicle-btn' : 'btn wizard-btn vehicle-btn'}>
                  <ButtonLoader isLoading={false} label={'continue'} />
                </button>
              </Formsy>
            </div>
            {/* ShopList */}
            <div className="body-content" style={{ paddingTop: 30 }}>
              <div className="form-container-inline">
                <div>
                  <div className="form-label-group">
                    {shopList && shopList.length > 0 &&
                      <div>
                        {shopList.map((shop, index) => (
                          <div key={index}>
                            <br />
                            <span><b>Name</b>: {shop.name} / </span>
                            <span><b>Address</b>: {shop.address} / </span>
                            <span><b>Rating</b>: {shop.rating} </span>
                            <br />
                          </div>
                        ))}
                      </div>
                    }
                  </div>
                  <button onClick={() => this.bookAppointment()} className="btn">
                    BookAppointment
                  </button>

                </div>
              </div>
            </div>
          </div>
          <div className="vehicle-bottom-line bottom-line">
            <img src={bottomLine} alt="line" />
          </div>
        </section>


      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    pageLoader: state.primary.pageLoader,
    step1Data: state.register.step1,
    step2response: state.register.step2response

  }
};

const mapDispatchToProps = dispatch => {
  return {
    showLoader: () => dispatch(actionCreators.showLoader()),
    hideLoader: () => dispatch(actionCreators.hideLoader()),
    saveSecondStep: (formData) => dispatch(actionCreators.saveSecondStep(formData)),
    saveSecondStepResponse: (response) => dispatch(actionCreators.saveSecondStepResponse(response)),
    saveFirstStep: (formData) => dispatch(actionCreators.saveFirstStep(formData)),
    saveThirdStep: (formData) => dispatch(actionCreators.saveThirdStep(formData)),
    saveThirdStepResponse: (response) => dispatch(actionCreators.saveThirdStepResponse(response)),
    saveFourthStep: (formData) => dispatch(actionCreators.saveFourthStep(formData)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ApiIntegrationPage);